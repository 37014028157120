import PropTypes from 'prop-types'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo/seo'
import StarredLink from '../components/starred-link/starred-link'
import HeroImg from '../assets/images/hero-landing-improve.png'

const query = graphql`
    query {
        contentfulMainPage {
            servicesTitle
            servicesSubtitle
            servicesItems {
                id
                title
                description
                shortDescription
                link
            }
        }
    }
`

const Content = ({ location }) => {
    const currentLocation =
        typeof window !== 'undefined' ? window.location.pathname : ''
    const currentLink = currentLocation.replace(/^\/+|\/+$/g, '')
    const data = useStaticQuery(query)
    const otherServices = data.contentfulMainPage.servicesItems.filter(item => {
        return item.link !== currentLink
    })
    const pageTitle = 'Improve an existing product'

    return (
        <Layout>
            <Seo
                title={`${pageTitle} | South Lane`}
                desc="Keeping your customers happy and making sure that all their needs, wants, and motivations are being satisfied is why you exist. We can help. Get in touch."
                pathname={location.pathname}
            />
            <div className="content-narrow">
                <div className="heading-container heading-container--how-we-can-help">
                    <p>How we can help</p>
                    <h2>{pageTitle}</h2>
                    <div className="tags-list">
                        <div className="tags-list__item">
                            <div className="tag-item">Discovery</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Ideation & Concept</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Wireframing</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Design</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Development</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Testing</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Quality Assurance</div>
                        </div>
                        <div className="tags-list__item">
                            <div className="tag-item">Project Management</div>
                        </div>
                    </div>

                    <div className="common-content-block">
                        <p>
                            Keeping your customers happy and making sure that
                            all their needs, wants, and motivations are being
                            satisfied is why you exist. It may be reimagining
                            existing features that make using your product more
                            useful. It may be adding features that allow them to
                            solve more of their problems. It may be onboarding
                            them with more clarity so they can start benefiting
                            from your product more quickly. It&apos;s a
                            never-ending process to ensure your product stays
                            relevant.
                        </p>
                    </div>
                </div>
            </div>

            <div className="content-hero-image content-hero-image--landing">
                <img src={HeroImg} alt="Improve an existing product" />
            </div>

            <div className="content-narrow">
                <div className="content-blocks-list content-blocks-list--services-landing">
                    <div className="common-content-block">
                        <p>
                            Gathering customer feedback and bringing it all
                            together into something digestible and actionable
                            can be pretty messy. We can help create clarity
                            around everything your customers are trying to tell
                            you by facilitating things like{' '}
                            <strong>customer interviews</strong>,{' '}
                            <strong>concept testing</strong>, and{' '}
                            <strong>ethnographic research</strong>. We can also
                            leverage technology to get answers quickly through
                            tools like <strong>Google Analytics</strong> and{' '}
                            <strong>Hotjar</strong>. It all depends on what
                            we&apos;re trying to find out.
                        </p>
                        <p>
                            Once we know where improvements are needed, we
                            evaluate how we best get there through our processes
                            of <strong>ideation</strong>,{' '}
                            <strong>concept</strong>,{' '}
                            <strong>wireframing</strong>,{' '}
                            <strong>design</strong>, and{' '}
                            <strong>development</strong>. We&apos;ll then create
                            a <strong>product backlog</strong> so that what
                            needs to be worked on and when can be
                            collaboratively prioritized. As we build out
                            improvements, we test them with real users, get your
                            feedback, and refine to make sure we are meeting
                            user goals.
                        </p>
                    </div>
                </div>

                <div className="content-blocks-list content-blocks-list--services-landing-2">
                    <div className="common-content-block">
                        <h6>More ways we can help</h6>
                    </div>
                </div>

                {otherServices.map(item => (
                    <p key={item.link} className="no-font-size">
                        <StarredLink text={item.title} link={item.link} />
                    </p>
                ))}
            </div>
        </Layout>
    )
}

Content.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
}

export default Content
